<template>
  <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg" />
      <h4> {{ title }} </h4>
    </q-card-section>
    <q-card-section style="max-width:400px; margin:0 auto;">
        <form v-if="!sent" @submit.prevent="setPassword">
          <q-field class="email" icon="email" type="text" label="" helper="" error-label="We need a valid email or mobile">
            <q-input v-model="form.identifier" :label="identifierLabel" outlined stack-label required="required">
              <template v-slot:prepend>
                <q-icon name="perm_identity" color="light-blue-5" />
              </template>
            </q-input>
          </q-field>
          <q-field class="password" icon="lock" label="" helper="" error-label="Write a password">
            <q-input :type="isPwd ? 'password' : 'text'" v-model="form.password" label="Password" stack-label outlined required="required">
              <template v-slot:prepend>
                <q-icon name="lock" color="light-blue-5" />
              </template>
              <template v-slot:append>
                <q-icon  :name="isPwd ? 'visibility_off' : 'visibility'"
                         class="cursor-pointer"
                         @click="isPwd = !isPwd" />
              </template>
            </q-input>
          </q-field>
          <q-field class="password" icon="lock" label="" helper="" error-label="Write a password">
            <q-input :type="isPwd2 ? 'password' : 'text'" v-model="form.password2" label="Confirm Password" outlined stack-label required="required">
              <template v-slot:prepend>
                <q-icon name="lock" color="light-blue-5" />
              </template>
              <template v-slot:append>
                <q-icon  :name="isPwd2 ? 'visibility_off' : 'visibility'"
                         class="cursor-pointer"
                         @click="isPwd2 = !isPwd2" />
              </template>
            </q-input>
          </q-field>
          <br>
          <br>
          <div class="row justify-start items-center">
            <q-btn :id="isRegister ? 'btn_register' : 'btn_password_reset'" class="submitLogin full-width text-white" rounded type="submit" size="lg">{{buttonTitle}}</q-btn>
          </div>
        </form>
        <h5 v-else class="my-text">
          {{setPasswordDoneText}}
          <div class="row justify-center items-center q-mt-md">
          <span>
              <q-btn color="primary" @click="navTo('login')">Sign In</q-btn>
          </span>
      </div>
        </h5>
      <br>
      <br>
      <div v-if="isRegister && !sent" class="row justify-center items-center">
          <span id="registerText"> Already registered for Portal Access?
              <a id="registerLink" @click="navTo('login')">Sign In</a>
          </span>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { QInput, QField, QBtn, QCard, QCardSection, Notify } from 'quasar'
import { mapActions } from 'vuex'

export default {
  meta () {
    return {
      title: this.isRegister ? 'Register' : 'Set Password'
    }
  },
  data () {
    return {
      isPwd: true,
      isPwd2: true,
      sent: false,
      method: getUrlParam('method'),
      form: {
        identifier: this.$route.query.identifier,
        type: this.$route.query.type,
        password: null,
        password2: null
      }
    }
  },
  mounted () {
    if (this.$route.name === 'set-password' && !['email', 'mobile'].includes(this.method)) {
      this.navTo('login')
    }
  },
  methods: {
    async setPassword () {
      try {
        this.$q.loading.show()
        let response = await this.$axios.post(this.action, {
          identifier: this.form.identifier,
          password: this.form.password,
          password_confirmation: this.form.password2,
          token: this.$route.params.token,
          source: getCookie('mb_source'),
          emailToken: getUrlParam('token'),
          method: this.method
        })
        let user = response.data
        this.$q.loading.hide()

        if (this.isRegister) {
          if (user.email_verified_at) {
            this.$q.notify({
              message: 'Registration Successful!',
              color: 'positive',
              timeout: 5000
            })
            this.$router.push({ name: 'login' })
          } else {
            this.sent = true
          }
          window.dataLayer.push({ event: 'registered' })
        } else {
          this.$router.replace({
            name: 'reset',
            query: {
              identifier: this.form.identifier
            }
          })
        }
      } catch (error) {
        console.log('🚀 ~ setPassword ~ error:', error)
        var e = error.response
        console.log('🚀 ~ setPassword ~ e:', e)
        var message

        if (e.status === 401) {
          message = e.data
        } else {
          var errors = Object.values(e.data.errors).flat()
          message = errors[0]
        }
        Notify.create({
          message,
          html: true,
          icon: 'lock',
          timeout: 5500,
          color: 'negative',
          textcolor: '#fff'
        })
        this.$q.loading.hide()
      }
    },
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    },
    ...mapActions('users', [])
  },
  computed: {
    identifierLabel () {
      if (this.$route.name === 'register') {
        return 'Email or Mobile'
      }

      return this.method === 'email' ? 'Email' : 'Mobile'
    },
    setPasswordDoneText () {
      if (this.isRegister) {
        if (this.withEmail) {
          return 'We have sent an email verification to your email address. Click the link in the email to complete your account activation process.'
        } else {
          return 'You have been registered successfully. When you login you will be asked to verify your mobile number'
        }
      }
      return 'The password was sent to the email specified during registration.'
    },
    withEmail () {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.identifier))
    },
    isRegister () {
      return this.$route.name === 'register'
    },
    title () {
      if (this.sent) {
        if (this.withEmail) {
          return 'Email Verification Sent'
        } else {
          return 'Registration Successful'
        }
      }

      return this.isRegister ? 'Register for Portal Access to Manage your Loan Account' : 'Reset password'
    },
    buttonTitle () {
      return this.isRegister ? 'Create Portal Access' : 'Update Password'
    },
    action () {
      return this.isRegister ? 'register' : 'password/reset'
    },
    backText () {
      return this.isRegister ? 'Already registered for Portal Access?' : 'Back to'
    }
  },
  components: {
    QField,
    QInput,
    QBtn,
    QCard,
    QCardSection
  }
}

</script>
<style lang="scss">
  .submitLogin {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
#registerText {
  color: #3f3356;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
#registerLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
</style>
